import React from 'react';

import './BattleForFirst.css';

function BattleForFirst({ size }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={`${size}%`}
      // width='597'
      height={`${size * 0.99}%`}
      // height='589'
      fill='none'
      viewBox='0 0 597 589'
    >
      <g id='growing_chart 1' clipPath='url(#clip0)'>
        <g id='backdrop'>
          <circle id='circle' cx='298.5' cy='395.5' r='298.5' fill='#D4EB4A'></circle>
          <g id='vortex' fill='#000'>
            <ellipse id='Ellipse 7' cx='311.378' cy='492.968' rx='19.629' ry='8.795'></ellipse>
            <path
              id='Subtract'
              fillRule='evenodd'
              d='M443.978 482.707c-1.652-3.674-4.215-7.24-7.615-10.653-6.387-6.412-15.679-12.232-27.24-17.141C386.004 445.096 353.996 439 318.588 439c-35.407 0-67.415 6.096-90.535 15.913-11.561 4.909-20.853 10.729-27.239 17.141-6.383 6.407-9.814 13.35-9.814 20.547 0 7.198 3.431 14.14 9.814 20.548 6.386 6.412 15.678 12.232 27.239 17.141 16.173 6.867 36.696 11.914 59.69 14.326v1.006c-56.12-5.867-97.743-27.38-97.743-53.021C190 462.446 247.571 438 318.588 438c63.061 0 115.52 19.275 126.483 44.707h-1.093z'
              clipRule='evenodd'
            ></path>
            <path
              id='Subtract_2'
              fillRule='evenodd'
              d='M233.557 485.219c1.077-2.343 2.727-4.626 4.915-6.822 4.192-4.209 10.309-8.045 17.946-11.288 15.271-6.484 36.438-10.519 59.87-10.519 23.432 0 44.599 4.035 59.87 10.519 7.638 3.243 13.754 7.079 17.946 11.288 4.188 4.204 6.406 8.725 6.406 13.38 0 4.655-2.218 9.175-6.406 13.38-4.192 4.208-10.308 8.044-17.946 11.287-10.675 4.533-24.231 7.869-39.427 9.466v1.006c37.193-3.888 64.779-18.146 64.779-35.139 0-19.986-38.155-36.187-85.222-36.187-41.793 0-76.56 12.774-83.826 29.629h1.095z'
              clipRule='evenodd'
            ></path>
            <path
              id='Subtract_3'
              fillRule='evenodd'
              d='M357.344 489.192c-.584-1.207-1.455-2.394-2.605-3.549-2.317-2.326-5.72-4.467-10.004-6.286-8.565-3.637-20.467-5.91-33.666-5.91-13.198 0-25.1 2.273-33.665 5.91-4.284 1.819-7.687 3.96-10.004 6.286-2.313 2.322-3.493 4.772-3.493 7.255 0 2.482 1.18 4.932 3.493 7.254 2.317 2.326 5.72 4.467 10.004 6.286 5.977 2.538 13.579 4.412 22.112 5.313v1.005c-21.019-2.197-36.609-10.255-36.609-19.858 0-11.295 21.563-20.451 48.162-20.451 23.62 0 43.268 7.219 47.374 16.745h-1.099z'
              clipRule='evenodd'
            ></path>
          </g>
        </g>
        <path
          id='dot2shadow'
          fill='url(#paint0_linear)'
          d='M64 400c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24z'
        ></path>
        <path
          id='dot3shadow'
          fill='url(#paint1_linear)'
          d='M155.5 340c12.979 0 23.5-10.745 23.5-24s-10.521-24-23.5-24-23.5 10.745-23.5 24 10.521 24 23.5 24z'
        ></path>
        <path
          id='dot4shadow'
          fill='url(#paint2_linear)'
          d='M244 287c13.255 0 24-10.521 24-23.5S257.255 240 244 240s-24 10.521-24 23.5 10.745 23.5 24 23.5z'
        ></path>
        <path
          id='dot5shadow'
          fill='url(#paint3_linear)'
          d='M342 249c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24z'
        ></path>
        <path
          id='dot6shadow'
          fill='url(#paint4_linear)'
          d='M445.5 214c12.979 0 23.5-10.745 23.5-24s-10.521-24-23.5-24-23.5 10.745-23.5 24 10.521 24 23.5 24z'
        ></path>
        <path
          id='dot2'
          fill='#6C63FF'
          d='M64 395c10.493 0 19-8.507 19-19s-8.507-19-19-19-19 8.507-19 19 8.507 19 19 19z'
        ></path>
        <path
          id='dot3'
          fill='#6C63FF'
          d='M155.5 335c10.217 0 18.5-8.507 18.5-19s-8.283-19-18.5-19-18.5 8.507-18.5 19 8.283 19 18.5 19z'
        ></path>
        <path
          id='dot4'
          fill='#6C63FF'
          d='M244 282c10.493 0 19-8.283 19-18.5s-8.507-18.5-19-18.5-19 8.283-19 18.5 8.507 18.5 19 18.5z'
        ></path>
        <path
          id='dot5'
          fill='#6C63FF'
          d='M342 244c10.494 0 19-8.507 19-19s-8.506-19-19-19c-10.493 0-19 8.507-19 19s8.507 19 19 19z'
        ></path>
        <path
          id='dot6'
          fill='#6C63FF'
          d='M445.5 209c10.217 0 18.5-8.507 18.5-19s-8.283-19-18.5-19-18.5 8.507-18.5 19 8.283 19 18.5 19z'
        ></path>
        <path
          id='girl1'
          fill='url(#paint5_linear)'
          d='M303.555 194.576s-1.479.684-2.502-1.541l-2.96 1.367-1.534-3.338 1.849-.854-6.872-24.704s.173-5.476-3.809-6.334c0 0-2.162-.799-10.641 2.22a227.363 227.363 0 00-8.496 3.204c-1.84-1.667-5.895-6.034-5.98-12.253a9.413 9.413 0 00-1.206-4.34l5.22-1.562-.114-.247 15.252-5.849c.682 1.483 7.682-.852 9.219-3.361.705-1.15-.443-1.822-1.894-2.212a10.388 10.388 0 00-6.207.318l-18.428 6.627-.215-.469a5.418 5.418 0 01-2.371.915l-1.041-.72 6.973-11.155c1.435.773 5.625-5.315 5.347-8.246-.128-1.344-1.448-1.188-2.841-.624a10.441 10.441 0 00-4.767 3.998l-8.689 13.276-1.743-1.205-1.238 3.34a24.67 24.67 0 00-5.735-2.414l-2.476-5.387a8.972 8.972 0 003.782-6.833 8.998 8.998 0 00-2.996-7.214 28.026 28.026 0 015.062-1.524s-14.56-12.158-21.624 3.697c0 0-.008.164-.013.447-.288.134-.561.267-.792.355l-5.985 2.285a8.572 8.572 0 00-1.273.567 8.143 8.143 0 00-1.686 1.429c-.382.354-.7.772-.94 1.236a3.17 3.17 0 00.53 3.096 9.29 9.29 0 002.517 2.09l2.534 1.641a3.208 3.208 0 011.669 1.89c.227-1.872-.564-3.699-1.285-5.442a3.994 3.994 0 01-.421-2.611 3.755 3.755 0 012.068-1.988 22.296 22.296 0 013.221-1.357c.45 4.073 2.119 10.221 8.014 11.179l1.871 4.07c-1.125 1.735-1.348 4.799 1.991 10.183l8.184 17.801s.256.045.701.106c-.532 2.682-1.186 10.078 6.895 12.713l-3.724 31.11s-14.758 19.29-12.094 23.297h.875l-.947 3.042-3.393-.723-1.524 7.191L256.796 249s1.981-5.423-6.954-6.49c0 0-1.594-.34-1.086-2.736l-2.163-.461 1.265-4.064h.024s12.735-13.948 15.4-25.968c2.038-9.195 6.411-26.191 8.356-33.681l11.361-5.25s7.387 19.97 8.751 22.937l.37-.171 1.534 3.338-2.219 1.025 3.069 6.676L313 195.608s-1.647-5.534-9.445-1.032z'
        ></path>
        <path
          id='boy1'
          fill='url(#paint6_linear)'
          d='M80.831 346.523l-3.437-5.261 3.757-28.261a62.674 62.674 0 00-6.746-8.558 62.577 62.577 0 01-1-10.589 142.961 142.961 0 00-4.678-19.915l5.905 1.603 8.59-20.755v-4.883l3.682-2.442s-4.909-7.325-9.817 0c0 0 1.227 4.884 0 7.325-1.228 2.442-4.91 10.988-4.91 10.988h-3.123l.67-3.663h-6.42a16.978 16.978 0 00-.944-1.22l4.91-10.988-2.302-1.717 8.437-10.491 1.227-21.975c4.878-1.618 5.986-6.45 6.178-9.528a2.075 2.075 0 00-.767-1.725 2.103 2.103 0 00-1.852-.4c-10.594 2.701-8.468 7.99-8.468 7.99l-3.681 20.754-5.075 7.934a10.96 10.96 0 00-2.126-4.852 11.036 11.036 0 00-4.16-3.298l2.77-3.446a22.396 22.396 0 00-12.214.588c-10.995 4.158-10.973 20.067.008 24.262a11.05 11.05 0 004.844.787v-1.22c.156 0 .307-.017.461-.024a43.35 43.35 0 00-.457 3.685l-.004.001c-2.455 0-2.455 13.429 1.227 15.871l-1.227 13.429v7.325c2.454 6.104 7.363 6.104 7.363 6.104l3.681 17.092a6.404 6.404 0 004.312.129l.597 4.754s-11.045 8.546-11.045 20.755l-.402.8-.825-.8-7.363 6.104L56.225 361h9.817c2.454-6.104-7.363-7.325-7.363-7.325l-.05-6.274 10.91-12.889a21.038 21.038 0 002.244 4.722l.094.89-1.128-.233-2.902 9.088 5.584 1.888A20.522 20.522 0 0091 348.753c-1.234-6.459-10.169-2.23-10.169-2.23z'
        ></path>
        <path
          id='girl2'
          fill='url(#paint7_linear)'
          d='M420.2 98.042c-3.866-2.362-22.329 7.207-28.552 8.719-4.561 1.109-14.712-2.96-19.819-5.217l.092-.908s-2.872-2.646-6.34-2.397c2.873-3.154 4.92-9.141 4.972-15.02l6.276-1.048s-10.66-19.354-21.713-7.113a5.23 5.23 0 00-2.41-2.516c-2.013-.816-4.204.608-5.777 2.107-1.574 1.499-3.255 3.272-5.425 3.301a.748.748 0 01-.456-.103.879.879 0 01-.246-.666 12.593 12.593 0 00-1.644-5.3c-3.061 2.109-3.771 6.275-4.056 9.987-.036.225-.015.455.062.668.149.249.375.441.644.548 1.938 1.006 4.257 1.737 6.273.9 2.418-1.006 3.584-3.92 5.957-5.028a4.77 4.77 0 014.15.138c-5.307 9.078.539 14.078 4.54 16.263.183.656.423 1.294.716 1.909-4.38-1.553-11.384-1.468-17.94 9.727l1.992.414-18.937 31.136s-3.611 3.926-2.254 6.39c1.356 2.465 7.528-.306 7.324-5.336-.091-2.237 4.115-8.883 8.812-15.574l-2.344 4.604a46.795 46.795 0 004.788 3.073c-3.105 4.734-5.876 12.832 4.309 19.86l2.713 4.928s1.294-.637 3.181-1.432l.646.913c1.816 2.846.686.969.686.969l12.561 17.75s-16.273 13.95-15.376 23.929l-2.975.121a4.04 4.04 0 00-3.821 3.369 4.064 4.064 0 00.107 1.821s14.361 13.272 17.972 9.346c3.611-3.926-2.764-6.186-2.764-6.186l-.153-3.773-.266-.162c1.901-4.604 13.153-24.579 14.855-27.513 2.34-4.037-1.119-10.515-5.012-19.248l14.085-.508s2.8 22.233 12.06 26.334l.167-.08-1.644 2.876a4.066 4.066 0 00-.235 3.545 4.04 4.04 0 002.643 2.368s18.852-5.128 17.456-10.282c-1.395-5.153-6.696-.945-6.696-.945l-3.268-1.877-.439.219c-1.905-2.791-15.056-25.237-15.091-27.986-.034-2.641-14.251-6.111-24.417-7.11l-1.221-5.864c1.48-.113 2.943-.39 4.362-.826 0 0-6.782-12.32-5.63-14.887 1.153-2.566 10.088.85 7.121-10.366l6.222-1.513.025-.249 16.915 6.27a13.501 13.501 0 0012.459-1.618l11.301-7.948c10.19 3.364 12.342-5.541 8.477-7.903z'
        ></path>
      </g>
      <defs>
        <linearGradient
          id='paint0_linear'
          x1='10891.3'
          x2='10891.3'
          y1='24078.6'
          y2='21869.1'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='gray' stopOpacity='0.25'></stop>
          <stop offset='0.535' stopColor='gray' stopOpacity='0.12'></stop>
          <stop offset='1' stopColor='gray' stopOpacity='0.1'></stop>
        </linearGradient>
        <linearGradient
          id='paint1_linear'
          x1='14898'
          x2='14898'
          y1='21343.9'
          y2='19134.3'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='gray' stopOpacity='0.25'></stop>
          <stop offset='0.535' stopColor='gray' stopOpacity='0.12'></stop>
          <stop offset='1' stopColor='gray' stopOpacity='0.1'></stop>
        </linearGradient>
        <linearGradient
          id='paint2_linear'
          x1='19703.5'
          x2='19703.5'
          y1='16924.8'
          y2='14761.3'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='gray' stopOpacity='0.25'></stop>
          <stop offset='0.535' stopColor='gray' stopOpacity='0.12'></stop>
          <stop offset='1' stopColor='gray' stopOpacity='0.1'></stop>
        </linearGradient>
        <linearGradient
          id='paint3_linear'
          x1='24310.2'
          x2='24310.2'
          y1='15438.3'
          y2='13228.7'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='gray' stopOpacity='0.25'></stop>
          <stop offset='0.535' stopColor='gray' stopOpacity='0.12'></stop>
          <stop offset='1' stopColor='gray' stopOpacity='0.1'></stop>
        </linearGradient>
        <linearGradient
          id='paint4_linear'
          x1='28103.3'
          x2='28103.3'
          y1='15403.3'
          y2='13193.8'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='gray' stopOpacity='0.25'></stop>
          <stop offset='0.535' stopColor='gray' stopOpacity='0.12'></stop>
          <stop offset='1' stopColor='gray' stopOpacity='0.1'></stop>
        </linearGradient>
        <linearGradient
          id='paint5_linear'
          x1='39465.4'
          x2='39465.4'
          y1='74475.8'
          y2='56767.1'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='gray' stopOpacity='0.25'></stop>
          <stop offset='0.535' stopColor='gray' stopOpacity='0.12'></stop>
          <stop offset='1' stopColor='gray' stopOpacity='0.1'></stop>
        </linearGradient>
        <linearGradient
          id='paint6_linear'
          x1='14839.4'
          x2='14839.4'
          y1='123604'
          y2='99834.6'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='gray' stopOpacity='0.25'></stop>
          <stop offset='0.535' stopColor='gray' stopOpacity='0.12'></stop>
          <stop offset='1' stopColor='gray' stopOpacity='0.1'></stop>
        </linearGradient>
        <linearGradient
          id='paint7_linear'
          x1='117061'
          x2='116263'
          y1='27749.5'
          y2='8146.82'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='gray' stopOpacity='0.25'></stop>
          <stop offset='0.535' stopColor='gray' stopOpacity='0.12'></stop>
          <stop offset='1' stopColor='gray' stopOpacity='0.1'></stop>
        </linearGradient>
        <clipPath id='clip0'>
          <path fill='#fff' d='M0 0h597v589H0z'></path>
        </clipPath>
      </defs>
    </svg>
  );
}

BattleForFirst.defaultProps = {
  size: '100',
};

export default BattleForFirst;
